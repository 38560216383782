import { Transform } from 'class-transformer';
import dayjs from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TransformNumberToDayjs(target: any, propertyKey: string) {
  Transform(data => data && dayjs.unix(data), {
    toClassOnly: true,
  })(target, propertyKey);

  Transform(date => date && date.unix(), {
    toPlainOnly: true,
  })(target, propertyKey);
}
