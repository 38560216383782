import Vue from 'vue';

interface State {
  currentShowCount: number;
};

export class GlobalLoadingScreenModule {
  private state = Vue.observable<State>({ currentShowCount: 0 }); 

  private incrementShowCount() {
    this.state.currentShowCount = this.state.currentShowCount + 1;
  }

  private decrementShowCount() {
    this.state.currentShowCount = this.state.currentShowCount - 1;
  }

  get show() {
    return this.state.currentShowCount > 0;
  }

  track: {
    <D>(task: Promise<D>): Promise<D>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...tasks: Promise<any>[]): Promise<any[]>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = async <D>(firstTask: Promise<D>, ...tasks: Promise<any>[]) => {
    this.incrementShowCount();
    try {
      if ( tasks.length === 0 ) return await firstTask;
      return await Promise.all([firstTask, ...tasks]);
    } finally {
      this.decrementShowCount();
    }
  };
}

export const globalLoadingScreenModule = new GlobalLoadingScreenModule();

export type LoadingScreenTrack = typeof globalLoadingScreenModule.track;
