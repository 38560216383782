import { Exclude, Expose } from 'class-transformer';

export default class PaginationParams {
  page: number = 0;
  limit: number = 100;

  @Exclude({ toClassOnly: true }) // 継承越しだとplainToClassでsetterを要求されるので無視させる
  @Expose()
  get offset() {
    if (this.page && this.limit)
      return this.page * this.limit;
    return 0;
  }

  resetPage() {
    this.page = 0;
  }
}
