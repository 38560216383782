import VueRouter from 'vue-router';

import { ROUTER_META_PAGE_TITLE } from '..';

export function withPageTitleUpdate(router: VueRouter) {

  // 画面から遷移する際、タイトルを切り替える
  router.beforeEach((to, from, next) => {
    const title = to.meta[ROUTER_META_PAGE_TITLE];
    document.title = `${title} - Belugaディクショナリー`;
    next();
  });
}
