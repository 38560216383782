import { Transform } from 'class-transformer';
import { Dayjs } from 'dayjs';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';
import { DefaultIsEmail, DefaultIsNotEmpty } from '@/decorators/validator/default_class_validator';
import { KbnConst, PermissionKbn } from '@/utils/kbn';

export const UserParams: (keyof User)[] = [
  'name',
  'mail',
  'permissionKbnArray',
];

export default class User {
  uuid?: string;

  @DefaultIsNotEmpty()
  name?: string;

  @DefaultIsEmail()
  @DefaultIsNotEmpty()
  mail?: string;

  @Transform(value => value || [])
  permissionKbnArray: PermissionKbn[] = [];

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  @TransformNumberToDayjs
  updatedAt?: Dayjs;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  get permissionKbnUserManagement() {
    return this.permissionKbnArray.includes(KbnConst.PERMISSION_KBN_USER_MANAGEMENT);
  }
}
