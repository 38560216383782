











import { Component, Prop, Vue } from 'vue-property-decorator';

import Me from '@/api/models/me';

@Component
export default class AccountSummary extends Vue {

  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;

}
