




















import { Component, Prop, Vue } from 'vue-property-decorator';

type Variant = 'default' | 'warning' | 'danger' ;

@Component
export default class FormviewLayout extends Vue {

  /**
   * タイトル
   */
  @Prop({ required: true })
  title!: string;

  /**
   * スタイル
   */
  @Prop({ default: 'default' })
  variant!: Variant;
}
