import { Transform, Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';
import { CategoryKbn, KbnConst, PermissionKbn } from '@/utils/kbn';

import CaseStudy from './case_study';
import Group from './group';

export default class Me {
  uuid?: string;

  name?: string;

  groupName?: string;

  groupUuid!: string;

  @Type(() => Group)
  @Transform(value => value || [])
  groupArray: Group[] = [];

  @Transform(value => value || [])
  permissionKbnArray: PermissionKbn[] = [];

  categoryKbn?: CategoryKbn;

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  constructor(init?: Partial<Me>) {
    Object.assign(this, init);
  }

  get canEditCaseStudies() {
    return this.permissionKbnArray.includes(KbnConst.PERMISSION_KBN_CASE_STUDY_EDIT);
  }

  get canUserManagement() {
    return this.permissionKbnArray.includes(KbnConst.PERMISSION_KBN_USER_MANAGEMENT);
  }

  canEditCaseStudy(caseStudy: CaseStudy) {
    return this.permissionKbnArray.includes(KbnConst.PERMISSION_KBN_CASE_STUDY_EDIT) // 自身が編集権限を持つ
      && caseStudy.createdGroupUuid === this.groupUuid; // 事例が自身のグループの場合
    ;
  }
}
