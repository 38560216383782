
























import { noop } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Group from '@/api/models/group';
import Me from '@/api/models/me';
import SwitchGroupButton from '@/components/SwitchGroupButton.vue';
import CoreSpinner from '@/components/utils/CoreSpinner.vue';

type State = 'loading' | 'loaded' | 'failed';

@Component({
  components: {
    CoreSpinner,
    SwitchGroupButton,
  }
})
export default class AccountSummaryMenuGroups extends Vue {
  /**
   * メニューオープンフラグ
   */
  @Prop({ default: true })
  open!: boolean;
  
  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;

  /**
   * グループ一覧取得 APIコールバック
   */
  @Prop({ required: true })
  getGroups!: () => Promise<Group[]>;

  /**
   * グループ切り替えコールバック
   */
  @Prop({ default: () => noop })
  switchGroup!: (group: Group) => void;

  groups: Group[] = [];
  state: State = 'loading';

  @Watch('open', { immediate: true })
  async onLoad(open: boolean) {
    if (!open) return;
    try {
      this.state = 'loading';
      this.groups = await this.getGroups();
      this.state = 'loaded';
    } catch (error) {
      this.state = 'failed';
    }
  }

}
