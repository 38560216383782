import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { withLoginCheck } from './plugins/login_check';
import { withMessagesShift } from './plugins/message_shift';
import { withPageTitleUpdate } from './plugins/page_title_update';
import { PagePermission, ROUTER_META_PAGE_PERMISSION, withPermissionCheck } from './plugins/permission_check';

Vue.use(VueRouter);

export const ROUTER_META_GLOBAL_NAV_HEADER_TAB = Symbol('tab');
export const ROUTER_META_PAGE_TITLE = Symbol('pageTitle');

const REGEX_UUID = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Body',
    component: () => import('@/pages/TheGlobalBody.vue'),
    children: [
      {
        path: 'users',
        name: 'UsersIndex',
        component: () => import('@/pages/UsersIndex.vue'),
        meta: {
          [ROUTER_META_PAGE_TITLE]: 'ユーザー管理',
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.USER_MANAGEMENT,
        },
      },
      {
        path: 'case_studies/new',
        name: 'CaseStudyNew',
        component: () => import('@/pages/CaseStudyNew.vue'),
        meta: {
          [ROUTER_META_GLOBAL_NAV_HEADER_TAB]: 'case_studies',
          [ROUTER_META_PAGE_TITLE]: '事例新規登録',
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.CASE_STUDY_EDIT,
        },
      },
      {
        path: 'case_studies',
        name: 'CaseStudiesIndex',
        component: () => import('@/pages/CaseStudiesIndex.vue'),
        meta: {
          [ROUTER_META_GLOBAL_NAV_HEADER_TAB]: 'case_studies',
          [ROUTER_META_PAGE_TITLE]: '事例検索',
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
        },
        children: [
          {
            path: `:case_study_uuid(${REGEX_UUID})`,
            name: 'CaseStudyShow',
            component: () => import('@/pages/CaseStudyShow.vue'),
            props: (route) => ({ caseStudyUuid: route.params['case_study_uuid'], fromListFlag: route.params['from_list_flag'] === 'true' }),
            meta: {
              [ROUTER_META_GLOBAL_NAV_HEADER_TAB]: 'case_studies',
              [ROUTER_META_PAGE_TITLE]: '事例詳細',
              [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
            },
          },
        ]
      },
      {
        path: `case_studies/:case_study_uuid(${REGEX_UUID})/edit`,
        name: 'CaseStudyEdit',
        component: () => import('@/pages/CaseStudyEdit.vue'),
        props: (route) => ({ caseStudyUuid: route.params['case_study_uuid'] }),
        meta: {
          [ROUTER_META_GLOBAL_NAV_HEADER_TAB]: 'case_studies',
          [ROUTER_META_PAGE_TITLE]: '事例編集',
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.CASE_STUDY_EDIT,
        },
      },
    ],
    redirect: { name: 'CaseStudiesIndex' },
  },
  {
    path: '/forbidden',
    name: 'ForbiddenBody',
    component: () => import('@/pages/TheForbiddenBody.vue'),
    children: [
      {
        path: 'ip-address-restriction',
        name: 'IpAddressRestrictionErrorShow',
        component: () => import('@/pages/IpAddressRestrictionErrorShow.vue'),
        meta: {
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
        },
      },
      {
        path: 'policy-confirm',
        name: 'PolicyConfirmShow',
        component: () => import('@/pages/PolicyConfirmShow.vue'),
        meta: {
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
        },
      },
      {
        path: 'multiple-session',
        name: 'MultipleSessionShow',
        component: () => import('@/pages/MultipleSessionShow.vue'),
        meta: {
          [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
        },
      }
    ]
  },
  {
    path: '*',
    name: 'NotFoundShow',
    component: () => import('@/pages/NotFoundShow.vue'),
    meta: {
      [ROUTER_META_PAGE_PERMISSION]: PagePermission.ALL,
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

withLoginCheck(router);
withPermissionCheck(router);
withPageTitleUpdate(router);
withMessagesShift(router);

export default router;
