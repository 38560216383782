import Vue from 'vue';

import { LS_CASE_STUDIES_TAB } from '@/utils/local_storage';

export type CaseStudiesTabType = 'search' | 'favorites';

interface State {
  tab: CaseStudiesTabType;
  version: number;
};

export class CaseStudiesTabState {
  private state = Vue.observable<State>({ tab: 'search', version: 0 }); 

  constructor() {
    this.load();
  }

  get tab() {
    return this.state.tab;
  }

  get version() {
    return this.state.version;
  }

  save(tab: CaseStudiesTabType) {
    if (this.state.tab === tab) return;
    sessionStorage.setItem(LS_CASE_STUDIES_TAB, tab);
    this.state.tab = tab;
    this.state.version++;
  }

  private load() {
    const tab = sessionStorage.getItem(LS_CASE_STUDIES_TAB);
    if (!tab) return ;
    this.state.tab = tab as CaseStudiesTabType;
    this.state.version++;
  }

  clear() {
    this.save('search');
  }

}

export const caseStudiesTabState = new CaseStudiesTabState();
