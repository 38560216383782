












import { Component, Prop, Vue } from 'vue-property-decorator';

import MessageCard from '@/components/MessageCard.vue';
import { Message } from '@/store/global_message';

@Component({
  components: {
    MessageCard,
  }
})
export default class TheGlobalMessageTemplate extends Vue {

  /**
   * メッセージ一覧
   */
  @Prop({ default: () => ([]) })
  messages!: Message[];

  displayTime(message: Message) {
    return message.level === 'error' ? 0 : 5000;
  }

}
