import { Dayjs } from 'dayjs';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';
import { DefaultIsNotEmpty } from '@/decorators/validator/default_class_validator';

export const FavoriteParams: (keyof Favorite)[] = [
  'title',
];

export default class Favorite {
  uuid?: string;

  @DefaultIsNotEmpty()
  title?: string;

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  @TransformNumberToDayjs
  updatedAt?: Dayjs;

  constructor(init?: Partial<Favorite>) {
    Object.assign(this, init);
  }
}
