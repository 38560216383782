import Vue from 'vue';

import api from '@/api';
import Config from '@/api/models/config';
interface State {
  config: Config | null;
};

export class GlobalConfig {
  private state = Vue.observable<State>({ config: null }); 

  getConfig = async () => {
    if (this.state.config) {
      return this.state.config;
    } else {
      // 単一実行のために複雑なロジックを組んだ結果バグを生んだので、それはやめて最初に複数回APIが呼ばれる可能性があることを許容した
      this.state.config = await api.getConfig();
      return this.state.config;
    }
  };

}

export const globalConfigModule = new GlobalConfig();
