import { Transform } from 'class-transformer';

import { CaseStudySortKbn, KbnConst } from '@/utils/kbn';

import CaseStudy from './case_study';
import PaginationParams from './pagination_params';

export const CaseStudiesSearchParams: (keyof CaseStudiesSearchForm)[] = [
  'offset',
  'limit',
  'searchContent',
  'unpublishedCaseStudyFlag',
  'tagDtsUuidArray',
  'sortKbn',
];

export default class CaseStudiesSearchForm extends PaginationParams {
  searchContent?: string;
  unpublishedCaseStudyFlag: boolean = false;

  @Transform(value => value || [])
  tagDtsUuidArray: string[] = [];

  @Transform(value => value || [])
  selectedCaseStudies: CaseStudy[] = [];

  sortKbn?: CaseStudySortKbn = KbnConst.CASE_STUDY_SORT_KBN_UPDATED_AT;

  clear() {
    this.searchContent = undefined;
    this.unpublishedCaseStudyFlag = false;
    this.tagDtsUuidArray = [];
    this.sortKbn = KbnConst.CASE_STUDY_SORT_KBN_UPDATED_AT;
  }
}
