import { Transform } from 'class-transformer';
import { Min, registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';
import { Dayjs } from 'dayjs';
import { isNumber } from 'lodash';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';
import { DefaultIsNotEmpty } from '@/decorators/validator/default_class_validator';
import i18n from '@/translations';
import { ErrorConst } from '@/utils/error_const';

export const GroupParams: (keyof Group)[] = [
  'policyConfirm',
  'policyMessage',
  'policyValidDate',
  'userConfirmReset',
];

export default class Group {
  uuid!: string;

  name!: string;

  maximumUserCount?: number;

  @Transform(value => value || [])
  mailAddressDomainCdArray: string[] = [];

  @Transform(value => value || [])
  inetArray: string[] = [];

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  @TransformNumberToDayjs
  updatedAt?: Dayjs;

  @DefaultIsNotEmpty()
  policyConfirm: boolean = false;

  @IsNotEmptyWhenPolicyConfirmTrue('policyConfirm')
  policyMessage?: string;

  @IsNotLessThanOneWhenPolicyConfirmTrue('policyConfirm')
  @IsNotNumberInputEmptyWhenPolicyConfirmTrue('policyConfirm')
  policyValidDate?: number;

  @DefaultIsNotEmpty()
  userConfirmReset?: boolean = false;

  constructor(init?: Partial<Group>) {
    Object.assign(this, init);
  }
}

function IsNotEmptyWhenPolicyConfirmTrue(property: string, validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'isNotEmptyWhenPolicyConfirmTrue',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[relatedPropertyName];
          if (relatedValue) {
            // このクラスにのみ責任を持つため、releatedValue をpolicyConfirmにしてもよいが、
            // インターフェイス的にpolicyConfirmをデコレータ内で指定しなければならないので、relatedValueのままにした。
            // が、必ずこれはpolicyConfirmになる。
            return (value !== '' && value !== null && value !== undefined);
          }
          return true;
        },
        defaultMessage() {
          return i18n.t(`error.${ErrorConst.VALIDATION_REQUIRED}`).toString();
        }
      },
    });
  };
}

// uv-number-inputを利用した場合、全角数字の入力があった場合、要素的には空になる。
// そこで「値を入れて下さい」と表示すると、ユーザがびっくりする。
// ので、このエラーを作成している。一応、数値かどうかの判定もいれておいた。
function IsNotNumberInputEmptyWhenPolicyConfirmTrue(property: string, validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'isNotNumberInputEmptyWhenPolicyConfirmTrue',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[relatedPropertyName];
          if (relatedValue) {
            return (value !== '' && value !== null && value !== undefined && isNumber(value));
          }
          return true;
        },
        defaultMessage() {
          return i18n.t(`error.${ErrorConst.VALIDATION_INVALID_INPUT_TYPE_ERORR}`, {value: '半角数字'}).toString();
        }
      },
    });
  };
}

function IsNotLessThanOneWhenPolicyConfirmTrue(property: string, validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function(object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'isNotLessThanOneWhenPolicyConfirmTrue',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[relatedPropertyName];
          if (relatedValue) {
            return (value !== null && value !== undefined && value > 0);
          }
          return true;
        },
        defaultMessage() {
          return i18n.t(`error.${ErrorConst.VALIDATION_LESS_THAN_VALUE}`, {value: 1}).toString();
        }
      },
    });
  };
}
