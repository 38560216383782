import { IsEmail, IsNotEmpty } from 'class-validator';
import { merge } from 'lodash';

import i18n from '@/translations';
import { ErrorConst } from '@/utils/error_const';

// デフォルトメッセージを上書きするため、
// もともとclass-validtorで定義されているデコレーターを再定義する。
// （デフォルト値を変更する方法は見つけられなかった）

export const DefaultIsNotEmpty: typeof IsNotEmpty = function(validationOptions) {
  return IsNotEmpty(
    merge({ message: i18n.t(`error.${ErrorConst.VALIDATION_REQUIRED}`).toString() }, validationOptions),
  );
};

export const DefaultIsEmail: typeof IsEmail = function(options, validationOptions) {
  return IsEmail(
    options,
    merge({ message: i18n.t(`error.${ErrorConst.VALIDATION_INCORRECT_MAIL_FORMAT}`).toString() }, validationOptions),
  );
};
