import CaseStudy from './case_study';

export const CaseStudyParams: (keyof CaseStudy)[] = [
  'mainAttachmentUuid',
  'attachmentUuidArray',
  'title',
  'description',
  'clientCompanyName',
  'agencyStaffName',
  'campaignStartAt',
  'campaignEndAt',
  'tagDtUuidArray',
  'publishKbn',
  'remarkContent'
];
