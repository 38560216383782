



import { Component, Inject, Vue } from 'vue-property-decorator';

import TheGlobalMessageTemplate from '@/components/TheGlobalMessageTemplate.vue';
import { GlobalMessageModule, Message  } from '@/store/global_message';

@Component({
  components: {
    TheGlobalMessageTemplate,
  }
})
export default class TheGlobalMessage extends Vue {

  @Inject()
  globalMessageModule!: GlobalMessageModule;

  onClose(message: Message) {
    this.globalMessageModule.remove(message);
  }

  get messages() {
    return this.globalMessageModule.messages;
  }

}
