/* eslint-disable @typescript-eslint/no-explicit-any */
import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';
import { isDayjs } from 'dayjs';

import i18n from '@/translations';
import { ErrorConst } from '@/utils/error_const';

export default function IsSameOrBefore(property: string, validationOptions?: ValidationOptions) {
  return function(object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'isSameOrBefore',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];
          return isDayjs(value) && isDayjs(relatedValue) && value.isSameOrBefore(relatedValue);
        },
        defaultMessage() {
          return i18n.t(`error.${ErrorConst.VALIDATION_INCORRECT_PERIOD}`).toString();
        },
      },
    });
  };
}
