
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CoreSpinner extends Vue {
  @Prop({ required: true })
  size!: number;
}
