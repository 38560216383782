import './styles/index.scss';
import './plugins';

import Vue from 'vue';

import i18n from '@/translations';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');
