import { classToPlain, plainToClass } from 'class-transformer';
import Vue from 'vue';

import CaseStudiesFavoritesForm from '@/api/models/case_studies_favorites_form';
import { LS_CASE_STUDIES_FAVORITE_FORM } from '@/utils/local_storage';

interface State {
  form: CaseStudiesFavoritesForm;
  version: number;
};

export class CaseStudiesFavoriteState {
  private state = Vue.observable<State>({ form: new CaseStudiesFavoritesForm(), version: 0 }); 

  constructor() {
    this.load();
  }

  get form() {
    return this.state.form;
  }

  get version() {
    return this.state.version;
  }

  save(form: CaseStudiesFavoritesForm) {
    sessionStorage.setItem(LS_CASE_STUDIES_FAVORITE_FORM, JSON.stringify(classToPlain(form)));
    this.state.form = form;
    this.state.version++;
  }

  private load() {
    const formDataJson = sessionStorage.getItem(LS_CASE_STUDIES_FAVORITE_FORM);
    if (!formDataJson) return null;
    this.state.form = plainToClass(CaseStudiesFavoritesForm, JSON.parse(formDataJson));
    this.state.version++;
  }

  clear() {
    this.save( new CaseStudiesFavoritesForm() );
  }

}

export const caseStudiesFavoriteState = new CaseStudiesFavoriteState();
