import { Dayjs } from 'dayjs';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';

export default class Attachment {
  uuid?: string;

  virusCheckKbn?: string;

  fileName?: string;

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  s3PresignedUri?: string;

  pdfData?: ArrayBuffer;

  // API接続時のパラメータとしては利用しない。システム内で利用。
  file?: File;

  constructor(init?: Partial<Attachment>) {
    Object.assign(this, init);
  }
}
