

























import { Component, Vue } from 'vue-property-decorator';

import PopupMenuButton from '@/components/utils/PopupMenuButton.vue';
import PopupMenuLayout from '@/components/utils/PopupMenuLayout.vue';

@Component({
  components: {
    PopupMenuLayout,
    PopupMenuButton
  }
})
export default class SettingsMenu extends Vue {
}
