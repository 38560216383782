export default class TagDt {
  uuid!: string;

  titleContent?: string;

  keyCode?: string;

  lineNo?: number;

  constructor(init?: Partial<TagDt>) {
    Object.assign(this, init);
  }
}
