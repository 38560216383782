import Vue from 'vue';

import api from '@/api';
import { generate } from '@/api/models/models.stories';
import Tag from '@/api/models/tag';
import TagDt from '@/api/models/tag_dt';

interface State {
  icons: { [key: string]: string };
  tags: Tag[];
};

export class GlobalTag {
  private state = Vue.observable<State>({ icons: {}, tags: [] }); 

  private mock = false;

  constructor(mock = false) {
    this.mock = mock;
    if (this.mock) {
      this.initializeMock();
    } else {
      this.initialize();
    }
  }

  private async initialize() {
    this.state.tags = await api.getTags();
  }

  private initializeMock() {
    const tag = new Tag();
    tag.uuid = 'tag uuid 1';
    tag.titleContent = 'タグ１';
    tag.keyCode = 'emotion';
    const tagDt = generate(TagDt);
    tag.tagDtJson.push(tagDt);
    this.state.tags.push(tag);
  }

  icon(key: string) {
    return require(`!!html-loader!../assets/icons/tag/${key}.svg`);
  }

  get tags() {
    return this.state.tags;
  }

}

export const globalTagModule = new GlobalTag();
