




























import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import FormviewLayout from '@/components/utils/FormviewLayout.vue';

@Component({
  components: {
    FormviewLayout,
  },
})
export default class SwitchGroupConfirmFormview extends Vue {  
  /**
   * グループ
   */
  @Prop({ required: true })
  group!: Group;
}
