

























import { noop } from 'vue-class-component/lib/util';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import SwitchGroupConfirmFormview from '@/components/SwitchGroupConfirmFormview.vue';
import PopupMenuButton from '@/components/utils/PopupMenuButton.vue';

@Component({
  components: {
    PopupMenuButton,
    SwitchGroupConfirmFormview,
  }
})
export default class SwitchGroupButton extends Vue {

  @Prop({ required: true})
  current!: boolean;

  @Prop({ required: true })
  group!: Group;

  @Prop({ default: () => noop })
  switchGroup!: (group: Group) => void;

  dialog = false;

  onSwitchGroup() {
    this.dialog = false;
    this.switchGroup(this.group);
  }

  get disabled() {
    return this.current;
  }
}
