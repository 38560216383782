import { classToPlain, plainToClass } from 'class-transformer';
import Vue from 'vue';

import CaseStudiesSearchForm from '@/api/models/case_studies_search_form';
import { LS_CASE_STUDIES_SEARCH_FORM } from '@/utils/local_storage';

interface State {
  form: CaseStudiesSearchForm;
  version: number;
};

export class CaseStudiesSearchState {
  private state = Vue.observable<State>({ form: new CaseStudiesSearchForm(), version: 0 }); 

  constructor() {
    this.load();
  }

  get form() {
    return this.state.form;
  }

  get version() {
    return this.state.version;
  }

  save(form: CaseStudiesSearchForm) {
    sessionStorage.setItem(LS_CASE_STUDIES_SEARCH_FORM, JSON.stringify(classToPlain(form)));
    this.state.form = form;
    this.state.version++;
  }

  private load() {
    const formDataJson = sessionStorage.getItem(LS_CASE_STUDIES_SEARCH_FORM);
    if (!formDataJson) return null;
    this.state.form = plainToClass(CaseStudiesSearchForm, JSON.parse(formDataJson));
    this.state.version++;
  }

  clear() {
    this.save( new CaseStudiesSearchForm() );
  }

}

export const caseStudiesSearchState = new CaseStudiesSearchState();
