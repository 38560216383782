// 区分
export type Kbn =
  '001' /* 公開区分 */ |
  '002' /* カテゴリー区分 */ |
  '003' /* ウイルスチェック区分 */ |
  '004' /* 権限区分 */ |
  '005' /* 事例ソート区分 */ |
  '006' /* 事例位置区分 */;

// 公開区分
export type PublishKbn =
  '00101' /* 公開 */ |
  '00102' /* 非公開 */;

// カテゴリー区分
export type CategoryKbn =
  '00201' /* Twitter */;

// ウイルスチェック区分
export type VirusCheckKbn =
  '00301' /* 未チェック */ |
  '00302' /* チェック済 */ |
  '00303' /* 感染 */;

// 権限区分
export type PermissionKbn =
  '00401' /* 事例編集 */ |
  '00402' /* ユーザー管理 */;

// 事例ソート区分
export type CaseStudySortKbn =
  '00501' /* 最新順 */ |
  '00502' /* お気に入り登録数 */ |
  '00503' /* ページビュー(7日間) */ |
  '00504' /* ページビュー(30日間) */;

// 事例位置区分
export type CaseStudyPositionKbn =
  '00601' /* 前 */ |
  '00602' /* 自身 */ |
  '00603' /* 次 */;

export const KbnConst = {
  // 公開区分
  PUBLISH_KBN: '001' as Kbn,
  
  // 公開区分(公開)
  PUBLISH_KBN_PUBLIC: '00101' as PublishKbn,

  // 公開区分(非公開)
  PUBLISH_KBN_NON_PUBLIC: '00102' as PublishKbn,

  // カテゴリー区分
  CATEGORY_KBN: '002' as Kbn,
  
  // カテゴリー区分(Twitter)
  CATEGORY_KBN_TWITTER: '00201' as CategoryKbn,

  // ウイルスチェック区分
  VIRUS_CHECK_KBN: '003' as Kbn,
  
  // ウイルスチェック区分(未チェック)
  VIRUS_CHECK_KBN_NO: '00301' as VirusCheckKbn,

  // ウイルスチェック区分(チェック済)
  VIRUS_CHECK_KBN_OK: '00302' as VirusCheckKbn,

  // ウイルスチェック区分(感染)
  VIRUS_CHECK_KBN_NG: '00303' as VirusCheckKbn,

  // 権限区分
  PERMISSION_KBN: '004' as Kbn,
  
  // 権限区分(事例編集)
  PERMISSION_KBN_CASE_STUDY_EDIT: '00401' as PermissionKbn,

  // 権限区分(ユーザー管理)
  PERMISSION_KBN_USER_MANAGEMENT: '00402' as PermissionKbn,

  // 事例ソート区分
  CASE_STUDY_SORT_KBN: '005' as Kbn,
  
  // 事例ソート区分(最新順)
  CASE_STUDY_SORT_KBN_UPDATED_AT: '00501' as CaseStudySortKbn,

  // 事例ソート区分(お気に入り登録数)
  CASE_STUDY_SORT_KBN_FAVORITE_COUNT: '00502' as CaseStudySortKbn,

  // 事例ソート区分(ページビュー(7日間))
  CASE_STUDY_SORT_KBN_DAYS_7_PV_COUNT: '00503' as CaseStudySortKbn,

  // 事例ソート区分(ページビュー(30日間))
  CASE_STUDY_SORT_KBN_DAYS_30_PV_COUNT: '00504' as CaseStudySortKbn,

  // 事例位置区分
  CASE_STUDY_POSITION_KBN: '006' as Kbn,
  
  // 事例位置区分(前)
  CASE_STUDY_POSITION_KBN_PREVIOUS: '00601' as CaseStudyPositionKbn,

  // 事例位置区分(自身)
  CASE_STUDY_POSITION_KBN_MYSELF: '00602' as CaseStudyPositionKbn,

  // 事例位置区分(次)
  CASE_STUDY_POSITION_KBN_NEXT: '00603' as CaseStudyPositionKbn,
} as const;

export const KbnValues = {
  // 公開区分
  '001': [
    '00101', // 公開
    '00102', // 非公開
  ],

  // カテゴリー区分
  '002': [
    '00201', // Twitter
  ],

  // ウイルスチェック区分
  '003': [
    '00301', // 未チェック
    '00302', // チェック済
    '00303', // 感染
  ],

  // 権限区分
  '004': [
    '00401', // 事例編集
    '00402', // ユーザー管理
  ],

  // 事例ソート区分
  '005': [
    '00501', // 最新順
    '00502', // お気に入り登録数
    '00503', // ページビュー(7日間)
    '00504', // ページビュー(30日間)
  ],

  // 事例位置区分
  '006': [
    '00601', // 前
    '00602', // 自身
    '00603', // 次
  ],
} as const;
