
















































import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import Me from '@/api/models/me';
import AccountSummary from '@/components/AccountSummary.vue';
import AccountSummaryMenu from '@/components/AccountSummaryMenu.vue';
import NavTabButton from '@/components/NavTabButton.vue';
import ServiceLogo from '@/components/ServiceLogo.vue';
import SettingsMenu from '@/components/SettingsMenu.vue';

import { TabType } from './TheGlobalNavHeaderTemplate';

@Component({
  components: {
    NavTabButton,
    AccountSummary,
    AccountSummaryMenu,
    ServiceLogo,
    SettingsMenu,
  }
})
export default class TheGlobalNavHeaderTemplate extends Vue {

  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;

  /**
   * 選択
   */
  @Prop({ required: true })
  selectedTab!: TabType;

  /**
   * 事例検索タブクリックイベント
   */
  @Prop({ default: () => noop })
  clickCaseStudiesTab!: () => Promise<void>;

  /**
   * ログアウトボタン コールバック
   */
  @Prop({ default: () => noop })
  logout!: () => void;

  /**
   * プロフィール設定ボタン コールバック
   */
  @Prop({ default: () => noop })
  profileSetting!: () => void;

  /**
   * パスワード変更 コールバック
   */
  @Prop({ default: () => noop })
  changePassword!: () => void;

  /**
   * グループ切り替え コールバック
   */
  @Prop({ default: () => noop })
  switchGroup!: (group: Group) => void;
  
  /**
   * グループ一覧取得 APIコールバック
   */
  @Prop({ required: true })
  getGroups!: () => Promise<Group[]>;

  open = false;

  get showSettingsMenu() {
    return this.me.canUserManagement;
  }

}
