import VueRouter from 'vue-router';

import api from '@/api';
import { globalLoadingScreenModule } from '@/store/global_loading_screen';

// ログインチェックを行う
export function withLoginCheck(router: VueRouter) {
  router.beforeEach(async (to, from, next) => {
    await globalLoadingScreenModule.track(api.getCheck());
    next();
  });
}
