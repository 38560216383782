import Vue from 'vue';

import api from '@/api';
import Me from '@/api/models/me';

interface State {
  me: Me | null;
};

export class GlobalMe {
  private state = Vue.observable<State>({ me: null }); 

  getMe = async () => {
    if (this.state.me) {
      return this.state.me;
    } else {
      // 単一実行のために複雑なロジックを組んだ結果バグを生んだので、それはやめて最初に複数回APIが呼ばれる可能性があることを許容した
      this.state.me = await api.getMe();
      return this.state.me;
    }
  };

}

export const globalMeModule = new GlobalMe();
