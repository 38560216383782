
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavTabButton extends Vue {
  @Prop({ default: false })
  selected!: boolean;

  @Prop({ default: '' })
  icon!: string;
}
