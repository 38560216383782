import { Expose, Transform, Type } from 'class-transformer';
import { Dayjs } from 'dayjs';
import _ from 'lodash';

import TransformNumberToDayjs from '@/decorators/transform/transform_number_to_dayjs';
import { DefaultIsNotEmpty } from '@/decorators/validator/default_class_validator';
import IsSameOrBefore from '@/decorators/validator/is_same_or_before';
import { KbnConst, PublishKbn } from '@/utils/kbn';

import Attachment from './attachment';

export default class CaseStudy {
  uuid?: string;
  @DefaultIsNotEmpty()
  title?: string;
  description?: string;
  remarkContent?: string;

  @DefaultIsNotEmpty()
  clientCompanyName?: string;

  @DefaultIsNotEmpty()
  agencyStaffName?: string;

  @Transform(value => value || [])
  tagDtUuidArray?: string[] = [];

  @Type(() => Attachment)
  mainAttachment?: Attachment;

  @Type(() => Attachment)
  @Transform(value => value || [])
  attachmentArray: Attachment[] = [];

  publishKbn?: PublishKbn;

  createdGroupUuid?: string;

  @Transform(value => value || [])
  favoriteUuidArray: string[] = [];

  @TransformNumberToDayjs
  createdAt?: Dayjs;

  @TransformNumberToDayjs
  updatedAt?: Dayjs;

  @TransformNumberToDayjs
  @IsSameOrBefore('campaignEndAt')
  @DefaultIsNotEmpty()
  campaignStartAt?: Dayjs;

  @TransformNumberToDayjs
  @DefaultIsNotEmpty()
  campaignEndAt?: Dayjs;

  days7PvCount?: number;

  days30PvCount?: number;

  totalFavoriteCount?: number;

  get isNonPublic() {
    return this.publishKbn === KbnConst.PUBLISH_KBN_NON_PUBLIC;
  }

  get subAttachment() {
    return this.attachmentArray[0];
  }

  set subAttachment(attachment: Attachment | undefined) {
    this.attachmentArray = attachment ? [attachment] : [];
  }

  @Expose()
  get mainAttachmentUuid() {
    return this.mainAttachment ? this.mainAttachment.uuid : undefined;
  }

  @Expose()
  get attachmentUuidArray() {
    return _.map( this.attachmentArray, 'uuid');
  }

  constructor(init?: Partial<CaseStudy>) {
    Object.assign(this, init);
  }
}
