import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { ErrorConst } from '@/utils/error_const';

import APIError from './api_error';

export class ExternalFileApi {

  private axios: AxiosInstance;
  constructor() {
    this.axios = axios.create();
  }

  async getFile(url: string) {
    const response: AxiosResponse<BlobPart> = await this.axios.request({
      method: 'get',
      url,
      responseType: 'blob',
    }).catch(err => err.response);
    if (response.status !== 200) {
      throw new APIError(response, { resultCode: ErrorConst.COMMON_GET_FILE_FAILED });
    }
    return this.responseToFile(response);
  }

  putFile = async (url: string, file: File) => {
    const data = new FormData();
    data.append('file', file);
    await this.axios.request({
      method: 'put',
      url,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).catch(err => err.response);;
  };

  private responseToFile(response: AxiosResponse<BlobPart>) {
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2)
        fileName = fileNameMatch[1];
    }
    return new File([response.data], fileName);
  }
}

export default new ExternalFileApi();
