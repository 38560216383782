/* eslint-disable @typescript-eslint/no-explicit-any */

import dayjs from 'dayjs';

import { ErrorConst } from '@/utils/error_const';
import { KbnConst } from '@/utils/kbn';

import APIError, { APIErrorDetail } from '../api_error';
import Attachment from './attachment';
import CaseStudy from './case_study';
import Config from './config';
import Favorite from './favorite';
import Group from './group';
import Me from './me';
import TagDt from './tag_dt';
import User from './user';

type Generator<T> = (parameters: {
  now: Date;
}) => T;

const generators: Record<string, Generator<any>> = {};
type Constructor<T> = new (...args: any[]) => T;

export function register<T>(
  type: Constructor<T>,
  generator: Generator<T>
) {
  const key = type.toString();
  generators[key] = generator;
}

// export function generate<T>(
export const generate = function <T>(
  type: Constructor<T>,
  options?: Partial<T>
): T {
  const key = type.toString();
  const generator = generators[key];

  if (!generator) {
    throw new Error(`型 ${key} はまだgenerateと一緒に使えません。まずregisterしてください。`);
  }

  const instance: T = generator({
    now: new Date(),
  });

  if (options) {
    Object.assign(instance, options);
  }

  return instance;
};

register(
  CaseStudy,
  ({ now }) => new CaseStudy({
    uuid: '6cdfe9ac-f57b-4578-8b49-48dc33e99376',
    title: 'テスト事例タイトル',
    clientCompanyName: '神羅カンパニー',
    agencyStaffName: '担当太郎',
    description: '説明文',
    remarkContent: 'テスト企業',
    createdGroupUuid: '1f67ad99-9a5d-4de0-a54b-a097eb2025c3',
    publishKbn: '00101',
    campaignStartAt: dayjs(now),
    campaignEndAt: dayjs(now),
    tagDtUuidArray: [
      'dt 0-0',
      'dt 0-1',
      'dt 0-2',
      'dt 0-3',
      'dt 0-4',
      'dt 0-5',
      'dt 0-6',
      'dt 0-7',
      'dt 0-8',
    ],
    mainAttachment: new Attachment({
      uuid: '26c28712-390d-49ae-9950-35b0d3446d56',
      fileName: 'fileNameqawsedrftgyhuiojhgfrtyujikvfrt6y7u8ihgt67yuhbh.pdf',
    }),
    attachmentArray: [
      new Attachment({
        uuid: '5313ca3e-0de2-43ed-bdde-7be00834b938',
        fileName: 'fileNameqawsedrftgyhuiojhgfrtyujikvfrt6y7u8ihgt67yuhbh.txt',
      }),
      new Attachment({
        uuid: 'd0694903-ee5f-4bf3-9263-5ab629a176a6',
        fileName: 'fileNameqawsedrftgyhuiojhgfrtyujikvfrt6y7u8ihgt67yuhbh.xlsx',
      }),
    ],
    totalFavoriteCount: 50,
    days7PvCount: 30,
    days30PvCount: 63330,
  })
);

register(
  Config,
  () => new Config({
    changePasswordUrl: 'http://dummy.example.com/',
    profileSettingsUrl: 'http://dummy.example.com/',
    userServicesUrl: 'http://dummy.example.com/',
  })
);

register(
  User,
  () => new User({
    uuid: '6cdfe9ac-f57b-4578-8b49-48dc33e99376',
    name: 'ユーザー名',
    mail: 'example@example.com',
    permissionKbnArray: [KbnConst.PERMISSION_KBN_CASE_STUDY_EDIT],
  })
);

register(
  Attachment,
  () => new Attachment({
    uuid: 'd0694903-ee5f-4bf3-9263-5ab629a176a6',
    fileName: 'fileNameqawsedrftgyhuiojhgfrtyujikvfrt6y7u8ihgt67yuhbh.xlsx',
  })
);

register(
  Me,
  () => new Me({
    uuid: '6cdfe9ac-f57b-4578-8b49-48dc33e99376',
    name: 'Meユーザー',
    groupName: 'ユニークビジョン',
    permissionKbnArray: ['00401'],
    groupUuid: '06cd7b11-7683-4f92-8a83-d66a7354ec1a',
    groupArray: [
      generate(Group, {uuid: '06cd7b11-7683-4f92-8a83-d66a7354ec1a', name: 'グループ1'}),
      generate(Group, {uuid: '031217eb-a4bb-4b09-b14a-b125fea00844', name: 'グループ2' }),
    ]
  })
);

register(
  APIError,
  () => new APIError({
    data: {
      resultCode: ErrorConst.COMMON_UNKNOWN,
      message: 'エラーメッセージ',
      details: [],
    },
    status: 400,
    statusText: '400',
    headers: {},
    config: {},
  })
);

register(
  APIErrorDetail,
  () => new APIErrorDetail({
    code: ErrorConst.VALIDATION_REQUIRED,
    message: '必須',
  })
);

register(
  Group,
  () => new Group({
    uuid: '86b65027-a7f9-43e3-bd3f-9d9f24e27b29',
    name: 'ユニークビジョン',
    maximumUserCount: 100,
    mailAddressDomainCdArray: ['example.com'],
    inetArray: ['0.0.0.0/32'],
    policyConfirm: true,
    policyMessage: 'ポリシーメッセージ',
    policyValidDate: 5,
  })
);

register(
  TagDt,
  () => new TagDt({
    uuid: 'tagdt1',
    titleContent: 'ウェブサイトへの誘導またはコンバージョン',
    keyCode: 'emotion',
  })
);

register(
  Favorite,
  () => new Favorite({
    uuid: '4600fffa-ee0e-4173-ac7b-a1e17d929e95',
    title: 'お気に入りA',
  })
);
