export default class Config {
  changePasswordUrl!: string;

  profileSettingsUrl!: string;

  userServicesUrl!: string;

  constructor(init?: Partial<Config>) {
    Object.assign(this, init);
  }
}
