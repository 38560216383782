
import PaginationParams from './pagination_params';

export const UsersSearchParams: (keyof UsersSearchForm)[] = [
  'offset',
  'limit',
];

export default class UsersSearchForm extends PaginationParams {
}
