






import { Component, Inject, Vue } from 'vue-property-decorator';

import CoreSpinner from '@/components/utils/CoreSpinner.vue';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';

@Component({
  components: {
    CoreSpinner
  }
})
export default class TheGlobalLoadingScreen extends Vue {

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  get show(): boolean {
    return this.globalLoadingScreenModule.show;
  }

}
