import { Transform, Type } from 'class-transformer';
import _ from 'lodash';

import TagDt from './tag_dt';

export default class Tag {
  uuid!: string;

  titleContent!: string;

  keyCode!: string;

  lineNo?: number;

  @Type(() => TagDt)
  @Transform(value => value || [])
  tagDtJson: TagDt[] = [];

  findDts(uuids: string[]) {
    const searchObjects = uuids.map(id => ({ uuid: id }));
    return _.intersectionBy(this.tagDtJson, searchObjects, 'uuid');
  }

  constructor(init?: Partial<Tag>) {
    Object.assign(this, init);
  }
}
