












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Message } from '@/store/global_message';

@Component
export default class MessageCard extends Vue {

  /**
   * メッセージ
   */
  @Prop({ required: true })
  message!: Message;

  /**
   * 表示する時間（ミリ秒） 0だと自動で閉じない
   */
  @Prop({ default: 0 })
  displayTime!: number;

  @Watch('displayTime', { immediate: true })
  onTimeoutClose() {
    if (this.displayTime) {
      setTimeout( () => this.$emit('close', this.message), this.displayTime );
    }
  }
  
  get variant() {
    return this.message.level;
  }

}
