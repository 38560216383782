import { merge } from 'lodash';

import error from './error.json';
import main from './ja.json';
import kbn from './kbn.json';

export default merge(
  main, // 基本的にメインにすべて定義する
  error, // erdで出力されるエラー値
  kbn, // erdで出力される区分値
);
