import VueRouter from 'vue-router';

import { globalMessageModule } from '@/store/global_message';

export function withMessagesShift(router: VueRouter) {

  // 画面から遷移する際、グローバルメッセージをシフトさせる。
  router.beforeEach((to, from, next) => {
    globalMessageModule.shiftMessages();
    next();
  });
}
