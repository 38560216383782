import _ from 'lodash';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

export type MessageLevel =
  | 'error'
  | 'warn'
  | 'info'
  | 'notice'
;

export type MessageWhen = 'now' | 'next';

let messageIdCounter = 0;
export class Message {
  level!: MessageLevel;
  content!: TranslateResult;
  readonly id!: number; // v-forのkey用

  constructor(level: MessageLevel, content: TranslateResult) {
    this.level = level;
    this.content = content;
    this.id = messageIdCounter++;
  }
}

interface State {
  current: Message[];
  next: Message[];
};

export class GlobalMessageModule {
  private state = Vue.observable<State>({ current: [], next: [] }); 

  private addMessage(level: MessageLevel, content: TranslateResult, when: MessageWhen = 'now') {
    const message = new Message(level, content);
    switch (when) {
      case 'now':
        this.state.current.push(message);
        break;
      case 'next':
        this.state.next.push(message);
        break;
    }
  }

  addMessages(level: MessageLevel, contents: TranslateResult[], when: MessageWhen = 'now') {
    contents.forEach(content => this.addMessage(level, content, when));
  }

  /**
   * setMessageは画面を切り替えないでメッセージを洗い替えする時に使用する。
   */
  private setMessage(level: MessageLevel, content: TranslateResult) {
    this.clear();
    this.addMessage(level, content);
  }

  setMessages = (level: MessageLevel, contents: TranslateResult[]) => {
    this.clear();
    this.addMessages(level, contents);
  };

  shiftMessages() {
    this.state.current = this.state.next;
    this.state.next = [];
  }

  clear() {
    this.state.current = [];
    this.state.next = [];
  }

  remove(message: Message) {
    this.state.current = _.without(this.state.current, message);
  }

  get messages() {
    return this.state.current;
  }
}

export const globalMessageModule = new GlobalMessageModule();

export type SetMessages = typeof globalMessageModule.setMessages;
