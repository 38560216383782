import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as convertKeys from 'convert-keys';
import toCamelCase from 'to-camel-case';
import toSnakeCase from 'to-snake-case';

export function applyCasingFix(client: AxiosInstance): void {
  client.interceptors.request.use((configuration: AxiosRequestConfig) => {
    const { data, params } = configuration;

    if (!(data instanceof FormData)) {
      toSnake(data, ['messageJson', 'botScenarioJson']);
    }

    if (!(params instanceof FormData)) {
      toSnake(params, ['messageJson', 'botScenarioJson']);
    }

    return {
      ...configuration,
      data,
      params,
    };
  });

  client.interceptors.response.use((response: AxiosResponse) => {
    const receivedData = response.data;
    if (response.config.responseType !== 'blob') {
      // blobの時以外はtoCamelして本当に良いかは議論の余地がある。
      toCamel(receivedData, ['message_json', 'bot_scenario_json']);
    }
    return {
      ...response,
      data: receivedData, 
    };
  }, (error) => {
    error.response = {
      ...error.response,
      data: convertKeys.toCamel(error.response.data),
    };
    return Promise.reject(error);
  });
}

/**
 * ### toSnake -- オブジェクトをスネークケースにする。
 * 
 * Messaging APIのデータについて、snake_caseにしたくないためにこの関数を作った。
 * ライブラリを探したが、あるkeyのvalueを再帰的にsnake_caseにすることを「除く」
 * ライブラリは存在しなかった。ので自作。
 * 
 * この関数は、excludeに指定されたkey、「未満」のオブジェクトのキーについて、
 * スネークケースにする。よって、excludeに指定したキー自体はsnake_caseに変換する。
 * 
 * 末尾再帰最適化に対応していないブラウザの方が多数派のようだが、
 * それほどスタックは深くならないはずなので、気にしないことにする。
 * 
 * https://kangax.github.io/compat-table/es6/
 * 
 * この関数自体、API側が適切にMessaging APIのデータを扱うように修正したら、不要になるので。
 *
 * この関数はそもそもexportすべきではないが、自動テストで必要なためにexportしている。そもそも論として、
 * フロント側でキャメルケース以外の変換を扱うことがないように、API側を修正することが筋。ので、
 * あえて、utilsに置かず、ここでexportをしている。
 *
 * @param data 
 * @param exclude ここで指定したキー「より下」のオブジェクトは、スネークケースにしない。指定はキャメルケースで行う。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toSnake(data: any, exclude?: string[]) {
  if (data instanceof Array) {
    for (let i = 0; data && i < data.length; i++) {
      toSnake(data[i], exclude);
    }
    return;
  }

  for (const key in data) { // ここのkeyはキャメルケースになっている。
    if (key !== toSnakeCase(key)) {
      Object.defineProperty(data, toSnakeCase(key),
        {
          value: data[key],
          writable: true,
          configurable: true,
          enumerable: true
        }
      );
      delete data[key];
    }
    // 除外(exclude)に指定されているキー「未満」のオブジェクトは、全てsnake_caseにしない。
    // typeof data[toSnakeCase(key)]は既に上でスネークケースにし、元のキャメルケースのオブジェクトは削除しているので、
    // スネークケースでアクセスする。
    // excludeにはキャメルケースではいっていることを想定している。
    if (!(exclude && exclude.includes(key)) && typeof data[toSnakeCase(key)] === 'object') {
      toSnake(data[toSnakeCase(key)], exclude);
    }
  }
};

/**
 * ### オブジェクトをキャメルケースにする。
 * 
 * メタプログラミングをしようと思ったが、自重した(多分、toSnakeとtoCamelは共通化できる)。のでコピペ。
 * toCamelObjectについてはほんとんどtoSnakeと構造が同一なのは↑の理由で意図的。
 * 
 * この関数はそもそもexportすべきではないが、自動テストで必要なためにexportしている。そもそも論として、
 * フロント側でキャメルケース以外の変換を扱うことがないように、API側を修正することが筋。ので、
 * あえて、utilsに置かず、ここでexportをしている。toSnakeでも同じことを書いているが、大事な事なので、二度書いた。
 * 
 * @param data 
 * @param exclude ここで指定したキー「より下」のオブジェクトは、キャメルケースにしない。指定はスネークケースで行う。
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toCamel(data: any, exclude?: string[]) {
  if (data instanceof Array) {
    for (let i = 0; data && i < data.length; i++) {
      toCamel(data[i], exclude);
    }
    return;
  }

  for (const key in data) { // ここでのkeyはスネークケース
    if (key !== toCamelCase(key)) {
      Object.defineProperty(data, toCamelCase(key),
        {
          value: data[key],
          writable: true,
          configurable: true,
          enumerable: true
        }
      );
      delete data[key];
    }
    // 除外(exclude)に指定されているキー「未満」のオブジェクトは、全てcamelCaseにしない。
    // typeof data[toCamelCase(key)]は既に上でキャメルケースにし、元のスネークケースのオブジェクトは削除しているので、
    // キャメルケースでアクセスする。
    // excludeにはいっているのも、スネークケースを想定。
    if (!(exclude && exclude.includes(key)) && typeof data[toCamelCase(key)] === 'object') {
      toCamel(data[toCamelCase(key)], exclude);
    }
  }
};
