import { AxiosResponse } from 'axios';
import _, { chain } from 'lodash';

import i18n from '@/translations';
import { SchemaType } from '@/utils/schema';

import { ApiResponse, ApiResponseDetail } from './api_response';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function implementsApiResponse(arg: any): arg is ApiResponse {
  return arg !== null &&
    typeof arg === 'object' &&
    typeof arg.resultCode === 'string';
}

export default class APIError<D extends ApiResponse | BlobPart> {
  readonly response: AxiosResponse<D>;
  readonly status: number;
  readonly resultCode?: string;
  readonly details: APIErrorDetail[] = [];
  readonly schema?: SchemaType;

  constructor(response: AxiosResponse<D>, options?: {resultCode?: string; schema?: SchemaType}) {
    options = options || {}; // options?.schemaの構文がstorybookで怒られるのでこれで回避。

    this.response = response;
    this.status = response.status;
    this.schema = options.schema;

    if (implementsApiResponse(response.data)) {
      this.resultCode = options.resultCode ? options.resultCode : response.data.resultCode;

      if (response.data.details) {
        this.details = _.chain(response.data.details)
          .sortBy(['key', 'code'])
          .map(d => new APIErrorDetail(d))
          .value();
      }
    } else {
      this.resultCode = options.resultCode;
    }
  }

  get message() {
    if (this.resultCode) {
      return i18n.t(`error.${this.resultCode}`);
    }
    return i18n.t(`status_codes.${this.status}`);
  }

  modelMessages() {
    return [
      this.message,
      ...this.details.map(d => d.modelMessage(this.schema))
    ];
  }

  get errors() {
    return chain(this.details)
      .keyBy('key')
      .mapValues( detail => detail.errorCodeMessage )
      .value();
  }
}

export class APIErrorDetail {
  readonly key?: string;
  readonly code: string;
  
  constructor(d: ApiResponseDetail) {
    this.code = d.code;
    this.key = d.key;
  }

  modelMessage(schema?: SchemaType) {
    const fieldPath = schema ? 
      `schema.${schema}.${this.key}` :
      `schema.${this.key}`;

    switch (this.key) {
      case '__all__': return this.errorCodeMessage;
      default: return `${i18n.t(fieldPath)}：${this.errorCodeMessage}`;
    }
  }

  get errorCodeMessage() {
    return `${i18n.t(`error.${this.code}`)}`;
  }
};
