













import qs from 'qs';
import { Component, Inject, Vue } from 'vue-property-decorator';

import { Api } from '@/api';
import Config from '@/api/models/config';
import Group from '@/api/models/group';
import Me from '@/api/models/me';
import { TabType } from '@/components/TheGlobalNavHeaderTemplate';
import TheGlobalNavHeaderTemplate from '@/components/TheGlobalNavHeaderTemplate.vue';
import { ROUTER_META_GLOBAL_NAV_HEADER_TAB } from '@/router';
import { CaseStudiesFavoriteState } from '@/store/case_studies_favorite_state';
import { CaseStudiesSearchState } from '@/store/case_studies_search_state';
import { CaseStudiesTabState } from '@/store/case_studies_tab_state';
import { GlobalConfig } from '@/store/global_config';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { GlobalMe } from '@/store/global_me';

@Component({
  components: {
    TheGlobalNavHeaderTemplate,
  }
})
export default class TheGlobalNavHeader extends Vue {

  active = 0;

  @Inject()
  api!: Api;

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  globalConfigModule!: GlobalConfig;

  @Inject()
  globalMeModule!: GlobalMe;

  @Inject()
  caseStudiesSearchState!: CaseStudiesSearchState;

  @Inject()
  caseStudiesFavoriteState!: CaseStudiesFavoriteState;

  @Inject()
  caseStudiesTabState!: CaseStudiesTabState;

  me = new Me();

  config = new Config();
  
  async created() {
    this.me = await this.globalMeModule.getMe();
    this.config = await this.globalConfigModule.getConfig();
  }

  get selectedTab(): TabType {
    return this.$route.meta[ROUTER_META_GLOBAL_NAV_HEADER_TAB] || 'none';
  }

  async switchGroup(group: Group) {
    await this.globalLoadingScreenModule.track(this.api.putMeGroup(group));
    sessionStorage.clear();
    location.href = '/';
  }

  async onClickCaseStudiesTab() {
    this.caseStudiesSearchState.clear();
    this.caseStudiesFavoriteState.clear();
    this.caseStudiesTabState.clear();

    if (this.$route.name !== 'CaseStudiesIndex') // NavigationDuplicatedエラー回避
      this.$router.push({ name: 'CaseStudiesIndex' });
  }

  async onLogout() {
    if (this.active) return;
    try {
      this.active++;
      const loginUri = await this.api.postLogout();
      window.location.href = loginUri;
    } catch {
      // ログアウトに失敗した場合は、システムエラーページに遷移させる。
      window.location.href = '/500.html';
    } finally {
      this.active--;
    }
  }

  onProfileSetting() {
    const queryString = qs.stringify({ 'origin_url': window.location.href });
    window.location.href = this.appendQueryParams(this.config.profileSettingsUrl, queryString);
  }

  onChangePassword() {
    const queryString = qs.stringify({ 'origin_url': window.location.href });
    window.open(this.appendQueryParams(this.config.changePasswordUrl, queryString));
  }

  appendQueryParams(url: string, queryString: string) {
    const params = new URL(url).search;
    if (params) {
      return `${url}&${queryString}`;
    } else {
      return `${url}?${queryString}`;
    }
  }

  async getGroups() {
    // 本来は専用のAPIを用意するべきだが、紆余曲折ありgetMeからグループ情報だけ抜き取る。
    const me = await this.api.getMe();
    return me.groupArray;
  }
}
