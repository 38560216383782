































import { noop } from 'vue-class-component/lib/util';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Group from '@/api/models/group';
import Me from '@/api/models/me';
import AccountSummaryMenuGroups from '@/components/AccountSummaryMenuGroups.vue';
import PopupMenuButton from '@/components/utils/PopupMenuButton.vue';
import PopupMenuLayout from '@/components/utils/PopupMenuLayout.vue';

@Component({
  components: {
    PopupMenuLayout,
    AccountSummaryMenuGroups,
    PopupMenuButton
  }
})
export default class AccountSummaryMenu extends Vue {

  /**
   * メニューオープンフラグ
   */
  @Prop({ default: true })
  open!: boolean;

  /**
   * ログアウト関数
   */
  @Prop({ default: () => noop })
  logout!: () => void;

  /**
 * プロフィール設定遷移関数
 */
  @Prop({ default: () => noop })
  profileSetting!: () => void;

  /**
   * パスワード変更遷移関数
   */
  @Prop({ default: () => noop })
  changePassword!: () => void;

  /**
   * ユーザー情報
   */
  @Prop({ required: true })
  me!: Me;
  
  /**
   * グループ一覧取得 APIコールバック
   */
  @Prop({ required: true })
  getGroups!: () => Promise<Group[]>;

  /**
   * グループ切り替えコールバック
   */
  @Prop({ default: () => noop })
  switchGroup!: (group: Group) => void;
}
