export const ErrorConst = {
  // 共通(成功)
  COMMON_SUCCESS: '00101',

  // 共通(不明なエラー)
  COMMON_UNKNOWN: '00102',

  // 共通(パラメーター不正)
  COMMON_INVALID_PARAMETER: '00103',

  // 共通(データなし)
  COMMON_NOT_FOUND: '00104',

  // 共通(データ重複)
  COMMON_ALREADY_EXISTS: '00105',

  // 共通(ログイン状態でない)
  COMMON_NOT_LOGIN: '00106',

  // 共通(Belugaログインサービスエラー)
  COMMON_BELUGA_LOGIN_SERVICE_ERROR: '00107',

  // 共通(認可されていない操作)
  COMMON_UNPERMITED: '00108',

  // 共通(ファイル取得失敗)
  COMMON_GET_FILE_FAILED: '00109',

  // 共通(ユーザー登録上限数超過)
  COMMON_USER_COUNT_MAX_LIMIT: '00110',

  // 共通(メールアドレス重複)
  COMMON_MAIL_ADDRESS_ALREADY_EXISTS: '00111',

  // 共通(お気に入り登録上限数超過)
  COMMON_FAVORITE_COUNT_MAX_LIMIT: '00112',

  // 共通(サービス不正)
  COMMON_INVALID_SERVICE: '00113',

  // 共通(カレントグループが不正)
  COMMON_INVALID_CURRENT_GROUP: '00114',

  // 共通(IPアドレスにによるアクセス制限)
  COMMON_IP_ADDRESS_RESTRICTION: '00115',

  // 共通(マルチセッションを検知)
  COMMON_MULTIPLE_SESSION: '00116',

  // 共通(ポリシーに同意をしていない)
  COMMON_POLICY_UNCONFIRMED: '00117',

  // 共通(ユーザ行動送信エラー)
  COMMON_USER_ACTION_SEND_FAILURE: '00118',

  // バリデーション(必須)
  VALIDATION_REQUIRED: '00201',

  // バリデーション(重複)
  VALIDATION_DUPLICATE: '00202',

  // バリデーション(不正なフォーマット)
  VALIDATION_INCORRECT_FORMAT: '00203',

  // バリデーション(不正な期間)
  VALIDATION_INCORRECT_PERIOD: '00204',

  // バリデーション(許可されていないドメイン)
  VALIDATION_UNPERMITED_DOMAIN: '00205',

  // バリデーション(不正なメールフォーマット)
  VALIDATION_INCORRECT_MAIL_FORMAT: '00206',

  // バリデーション(ファイルサイズ超え)
  VALIDATION_FILE_SIZE_OVER: '00207',

  // バリデーション(ファイル種類違い)
  VALIDATION_FILE_TYPE_MISSMATCH: '00208',

  // バリデーション(より小さい値)
  VALIDATION_LESS_THAN_VALUE: '00209',

  // バリデーション(の値ではない)
  VALIDATION_INVALID_INPUT_TYPE_ERORR: '00210',
};
