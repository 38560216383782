import Vue from 'vue';

import APIError from '@/api/api_error';
import { globalMessageModule } from '@/store/global_message';
import i18n from '@/translations';

import { ErrorConst } from './error_const';

function handleError(error: Error) {
  if (error instanceof APIError) {
    switch (error.resultCode) {
      default:
        globalMessageModule.setMessages('error', error.modelMessages());
        break;
    }
  } else {
    // 予期せぬエラー処理
    if (location.hostname === 'localhost') { // デバック用
      //eslint-disable-next-line no-console
      console.log(error);
    }
    globalMessageModule.setMessages('error', [i18n.t(`error.${ErrorConst.COMMON_UNKNOWN}`)]);
  }
}

// Vue内エラー
Vue.config.errorHandler = handleError;

// Vue.config.errorHandlerでキャッチされていないエラー
window.addEventListener('error', event => {
  handleError(event.error);
});

// Promiseのエラー
window.addEventListener('unhandledrejection', event => {
  handleError(event.reason);
});
