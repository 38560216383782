








import '@/utils/error_handler';

import { Component, Provide, Vue } from 'vue-property-decorator';

import api from '@/api';
import externalFileApi from '@/api/external_file_api';
import TheGlobalLoadingScreen from '@/components/utils/TheGlobalLoadingScreen.vue';
import TheGlobalMessage from '@/pages/TheGlobalMessage.vue';
import TheGlobalNavHeader from '@/pages/TheGlobalNavHeader.vue';
import { caseStudiesFavoriteState } from '@/store/case_studies_favorite_state';
import { caseStudiesSearchState } from '@/store/case_studies_search_state';
import { caseStudiesTabState } from '@/store/case_studies_tab_state';
import { globalConfigModule } from '@/store/global_config';
import { globalLoadingScreenModule } from '@/store/global_loading_screen';
import { globalMeModule } from '@/store/global_me';
import { globalMessageModule } from '@/store/global_message';
import { globalTagModule } from '@/store/global_tag';

@Component({
  components:{
    TheGlobalLoadingScreen,
    TheGlobalNavHeader,
    TheGlobalMessage,
  }
})
export default class App extends Vue {
  @Provide() api = api;
  @Provide() externalFileApi = externalFileApi;
  @Provide() globalTagModule = globalTagModule;
  @Provide() globalLoadingScreenModule = globalLoadingScreenModule;
  @Provide() globalMessageModule = globalMessageModule;
  @Provide() globalMeModule = globalMeModule;
  @Provide() caseStudiesSearchState = caseStudiesSearchState;
  @Provide() caseStudiesFavoriteState = caseStudiesFavoriteState;
  @Provide() caseStudiesTabState = caseStudiesTabState;
  @Provide() globalConfigModule = globalConfigModule;
}
