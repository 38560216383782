import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { classToPlain, plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import _ from 'lodash';
import qs from 'qs';

import i18n from '@/translations';
import { ErrorConst } from '@/utils/error_const';
import { CaseStudyPositionKbn} from '@/utils/kbn';
import { SchemaType } from '@/utils/schema';

import APIError from './api_error';
import { ApiDataListResponse, ApiDataResponse, ApiDateWithInfomationResponse, ApiResponse } from './api_response';
import { applyCasingFix } from './fix_casing';
import Attachment from './models/attachment';
import CaseStudiesFavoritesForm, { CaseStudiesFavoritesParams } from './models/case_studies_favorites_form';
import CaseStudiesSearchForm, { CaseStudiesSearchParams } from './models/case_studies_search_form';
import CaseStudy from './models/case_study';
import { CaseStudyParams } from './models/case_study_params';
import Config from './models/config';
import Favorite, { FavoriteParams } from './models/favorite';
import Group, { GroupParams } from './models/group';
import Me from './models/me';
import Policy from './models/policy';
import Tag from './models/tag';
import User, { UserParams } from './models/user';
import UsersSearchForm, { UsersSearchParams } from './models/users_search_form';
;

const API_BASE_URL = '/api/v1/member';

export const emptyDataList = {
  items: [],
  totalCount: 0,
};

export class Api {
  private currentGroupUuid?: string;
  private axios: AxiosInstance;
  constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL: baseURL,
      headers: { 'Content-Type': 'application/json' },
      paramsSerializer: (params) => {
        return qs.stringify(params, {
          encodeValuesOnly: true, // デフォルトでは':'等の記号がエンコードされなかったので定義
          arrayFormat: 'comma', // API側のactix_webが'[0]'表記の配列を扱えなかったため、カンマ区切りとした
        });
      },
    });
    applyCasingFix(this.axios);
  }

  getConfig = async () => {
    return await this.requestData({
      method: 'get',
      url: '/config',
    }, Config, undefined);
  };

  getMe = async () => {
    const me = await this.requestData({
      method: 'get',
      url: '/me',
    }, Me, undefined);
    this.currentGroupUuid = me.groupUuid;
    return me;
  };

  getCheck = async () => {
    return await this.requestCall({
      method: 'get',
      url: '/check',
    }, undefined);
  };

  putMeGroup = async (group: Group) => {
    await this.requestCall({
      method: 'put',
      url: '/me/group',
      data: { 'group_uuid': group.uuid },
    }, undefined);
  };

  getTags = async () => {
    const { items } = await this.requestDataList({
      method: 'get',
      url: '/tags',
    }, Tag, undefined);
    return items;
  };

  getCaseStudies = async (caseStudiesSearchForm: CaseStudiesSearchForm) => {
    return await this.requestDataList({
      method: 'get',
      url: '/case_studies',
      params: _.pick(classToPlain(caseStudiesSearchForm), CaseStudiesSearchParams),
    }, CaseStudy, undefined);
  };

  getCaseStudy = async (caseStudyUuid: string) => {
    return await this.requestData({
      method: 'get',
      url: `/case_studies/${caseStudyUuid}`,
    }, CaseStudy, undefined);
  };

  getNeighboringCaseStudy = async (caseStudyUuid: string, caseStudiesSearchForm: CaseStudiesSearchForm, caseStudyPositionKbn: CaseStudyPositionKbn) => {
    return await this.requestDataWithInformation({
      method: 'get',
      url: `/case_studies/${caseStudyUuid}/neighboring`,
      params: {..._.pick(classToPlain(caseStudiesSearchForm), CaseStudiesSearchParams), caseStudyPositionKbn},
    }, CaseStudy, undefined);
  };

  getAttachment = async (caseStudyUuid: string, attachmentUuid: string) => {
    return await this.requestData({
      method: 'get',
      url: `/case_studies/${caseStudyUuid}/attachments/${attachmentUuid}`,
    }, Attachment, undefined);
  };

  postAttachment = async (fileName: string) => {
    return await this.requestData({
      method: 'post',
      url: '/attachments',
      data: {fileName}
    }, Attachment, 'AttachmentParams');
  };

  postCaseStudy = async (caseStudy: CaseStudy) => {
    return await this.requestUuid({
      method: 'post',
      url: '/case_studies',
      data: _.pick(classToPlain(caseStudy), CaseStudyParams)
    }, 'CaseStudyParams');
  };

  putCaseStudy = async (caseStudy: CaseStudy) => {
    await this.requestCall({
      method: 'put',
      url: `/case_studies/${caseStudy.uuid}`,
      data: _.pick(classToPlain(caseStudy), CaseStudyParams)
    }, 'CaseStudyParams');
  };

  deleteCaseStudy = async (caseStudyUuid: string) => {
    await this.requestCall({
      method: 'delete',
      url: `/case_studies/${caseStudyUuid}`,
    }, undefined);
  };

  postLogout = async () => {
    const res = await this.request<Required<ApiResponse>>({
      method: 'post',
      url: '/logout',
    }, undefined);
    return res.data.loginUri;
  };

  getUsers = async (usersSearchForm: UsersSearchForm) => {
    return await this.requestDataList({
      method: 'get',
      url: '/users',
      params: _.pick(classToPlain(usersSearchForm), UsersSearchParams),
    }, User, undefined);
  };

  postUser = async (user: User) => {
    return await this.requestUuid({
      method: 'post',
      url: '/users',
      data: _.pick(classToPlain(user), UserParams)
    }, 'UserParams');
  };

  deleteUser = async (user: User) => {
    await this.requestCall({
      method: 'delete',
      url: `/users/${user.uuid}`,
    }, undefined);
  };

  getGroup = async (groupUuid: string) => {
    return await this.requestData({
      method: 'get',
      url: `/groups/${groupUuid}`,
    }, Group, undefined);
  };

  putGroup = async (group: Group) => {
    await this.requestCall({
      method: 'put',
      url: `/groups/${group.uuid}`,
      data: _.pick(classToPlain(group), GroupParams)
    }, 'GroupParams');
  };

  getFavorites = async () => {
    return await this.requestDataList({
      method: 'get',
      url: '/favorites',
    }, Favorite, undefined);
  };

  postFavorite = async (favorite: Favorite) => {
    return await this.requestUuid({
      method: 'post',
      url: '/favorites',
      data: _.pick(classToPlain(favorite), FavoriteParams)
    }, 'FavoriteParams');
  };

  putFavorite = async (favorite: Favorite) => {
    await this.requestCall({
      method: 'put',
      url: `/favorites/${favorite.uuid}`,
      data: _.pick(classToPlain(favorite), FavoriteParams)
    }, 'FavoriteParams');
  };

  deleteFavorite = async (favorite: Favorite) => {
    await this.requestCall({
      method: 'delete',
      url: `/favorites/${favorite.uuid}`,
    }, undefined);
  };

  getFavoriteCaseStudies = async (paseStudiesFavoritesForm: CaseStudiesFavoritesForm) => {
    return await this.requestDataList({
      method: 'get',
      url: `/favorites/${paseStudiesFavoritesForm.favoriteUuid}/case_studies`,
      params: _.pick(classToPlain(paseStudiesFavoritesForm), CaseStudiesFavoritesParams),
    }, CaseStudy, undefined);
  };

  getNeighboringFavoriteCaseStudy = async (caseStudyUuid: string, paseStudiesFavoritesForm: CaseStudiesFavoritesForm, caseStudyPositionKbn: CaseStudyPositionKbn) => {
    return await this.requestDataWithInformation({
      method: 'get',
      url: `/favorites/${paseStudiesFavoritesForm.favoriteUuid}/case_studies/${caseStudyUuid}/neighboring`,
      params: {..._.pick(classToPlain(paseStudiesFavoritesForm), CaseStudiesSearchParams), caseStudyPositionKbn},
    }, CaseStudy, undefined);
  };

  putFavoriteCaseStudy = async (favorite: Favorite, caseStudy: CaseStudy) => {
    await this.requestCall({
      method: 'put',
      url: `/favorites/${favorite.uuid}/case_studies/${caseStudy.uuid}`,
    }, undefined);
  };
  
  deleteFavoriteCaseStudy = async (favorite: Favorite, caseStudy: CaseStudy) => {
    await this.requestCall({
      method: 'delete',
      url: `/favorites/${favorite.uuid}/case_studies/${caseStudy.uuid}`,
    }, undefined);
  };

  getPolicy = async () => {
    return await this.requestData({
      method: 'get',
      url: '/policy',
    }, Policy, undefined);
  };

  putPolicy = async () => {
    await this.requestCall({
      method: 'put',
      url: '/policy',
      data: {},
    }, undefined);
  };

  private async requestCall(config: AxiosRequestConfig, schema: SchemaType | undefined) { // schemaは指定忘れをしないようあえてオプションにしない。
    await this.request<ApiDataResponse>(config, schema);
  }

  private async requestUuid<T>(config: AxiosRequestConfig, schema: SchemaType | undefined) {
    const res = await this.request<ApiDataResponse>(config, schema);
    return res.data.data.uuid;
  }

  private async requestData<T>(config: AxiosRequestConfig, model: ClassType<T>, schema: SchemaType | undefined) {
    const res = await this.request<ApiDataResponse>(config, schema);
    return plainToClass(model, res.data.data);
  }

  private async requestDataWithInformation<T>(config: AxiosRequestConfig, model: ClassType<T>, schema: SchemaType | undefined) {
    const res = await this.request<ApiDateWithInfomationResponse>(config, schema);
    return {
      item: plainToClass(model, res.data.data),
      nextExistsFlag: res.data.nextExistsFlag,
      previousExistsFlag: res.data.previousExistsFlag,
    };
  }

  private async requestDataList<T>(config: AxiosRequestConfig, model: ClassType<T>, schema: SchemaType | undefined) {
    const res = await this.request<ApiDataListResponse>(config, schema);
    return {
      items: plainToClass(model, res.data.data),
      totalCount: res.data.totalCount,
    };
  }

  private async request<D extends ApiResponse>(config: AxiosRequestConfig, schema: SchemaType | undefined) {
    if (!config.headers) config.headers = {};
    if (this.currentGroupUuid) config.headers['current-group-uuid'] = this.currentGroupUuid;
    const response: AxiosResponse<D> = await this.axios.request<D>(config)
      .catch(err => err.response);
    if (response.status !== 200) {
      switch (response.status) {
        case 401:
          switch (response.data.resultCode) {
            case ErrorConst.COMMON_NOT_LOGIN:
              if (response.data.loginUri) {
                window.location.href = response.data.loginUri;
              } else {
                window.location.href = '/500.html';
              }
              break;
            case ErrorConst.COMMON_MULTIPLE_SESSION:
              window.location.href = '/forbidden/multiple-session';
              break;
            case ErrorConst.COMMON_IP_ADDRESS_RESTRICTION:
              window.location.href = '/forbidden/ip-address-restriction';
              break;
            case ErrorConst.COMMON_POLICY_UNCONFIRMED:
              window.location.href = '/forbidden/policy-confirm';
              break;
            default:
              window.location.href = '/500.html';
          }
          break;
        case 400:
          if (response.data.resultCode === ErrorConst.COMMON_INVALID_CURRENT_GROUP) {
            alert(i18n.t(`error.${ErrorConst.COMMON_INVALID_CURRENT_GROUP}`));
            location.reload();
          }
          break;
      }
      throw new APIError(response, { schema });
    }
    return response;
  }

  // TODO 真の意味でのBaseResponseを設計し、このメソッドの問題を解消すること。
  private async blobRequest(config: AxiosRequestConfig) {
    const blobAxios = axios.create(config);
    applyCasingFix(blobAxios);

    const requestConfig = Object.assign(
      {
        baseURL: API_BASE_URL,
        responseType: 'blob',
      },
      config
    );
    const response: AxiosResponse<BlobPart> = await blobAxios(requestConfig).catch(err => err.response);

    if (response.status !== 200) {
      // 404であっても、401であっても、500であっても、取得できないことには変わりがないため。
      throw new APIError(response, {
        resultCode: ErrorConst.COMMON_GET_FILE_FAILED
      });
    }
    return response;
  }

  private responseToFile(response: AxiosResponse<BlobPart>) {
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2)
        fileName = fileNameMatch[1];
    }
    return new File([response.data], fileName);
  }
}

export default new Api(API_BASE_URL);

