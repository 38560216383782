import { find } from 'lodash';

import Favorite from './favorite';
import PaginationParams from './pagination_params';

export const CaseStudiesFavoritesParams: (keyof CaseStudiesFavoritesForm)[] = [
  'offset',
  'limit',
];

export default class CaseStudiesFavoritesForm extends PaginationParams {
  favoriteUuid: string | null = null;
  
  fixFavoriteUuid(favorites: Favorite[]) {
    if (favorites.length === 0) {
      this.favoriteUuid = null;
      return;
    }
    const favorite = find(favorites, ['uuid', this.favoriteUuid]);
    if (!favorite) {
      this.favoriteUuid = favorites[0].uuid || null;
    }
  }
}
