import VueRouter, { NavigationGuard } from 'vue-router';

export const ROUTER_META_PAGE_PERMISSION = Symbol('pagePermission');
import { globalMeModule } from '@/store/global_me';
import { globalMessageModule } from '@/store/global_message';

export const PagePermission = {
  // 全ユーザーアクセス可能
  ALL: 'all', 

  // 事例編集権限ありならアクセス可能
  CASE_STUDY_EDIT: 'case_study_edit', 
  
  // ユーザー管理権限ありならアクセス可能
  USER_MANAGEMENT: 'user_management',
};

const rootLocation = {name: 'CaseStudiesIndex'};

// ユーザー単位の大まかなアクセス権限をチェックする。データ単位の細かな権限チェックは各ページ内のcreatedにて行う
export function withPermissionCheck(router: VueRouter) {
  router.beforeEach((to, from, next) => {
    switch (to.meta[ROUTER_META_PAGE_PERMISSION]) {
      case PagePermission.ALL:
        next();
        break;

      case PagePermission.CASE_STUDY_EDIT:
        checkCanEditCaseStudies(to, from, next);
        break;

      case PagePermission.USER_MANAGEMENT:
        checkCanUserManagement(to, from, next);
        break;

      default:
        nonPermissionRoot(to, from, next);
        break;
    };
  });
}

const checkCanEditCaseStudies: NavigationGuard = async (to, from, next) => {
  try {
    const me = await globalMeModule.getMe();
    if (me.canEditCaseStudies) {
      next();
    } else {
      nonPermissionRoot(to, from, next);
    }
  } catch (error) {
    globalMessageModule.addMessages('error', error.modelMessages(), 'next');
    next(rootLocation);
  }
};

const checkCanUserManagement: NavigationGuard = async (to, from, next) => {
  try {
    const me = await globalMeModule.getMe();
    if (me.canUserManagement) {
      next();
    } else {
      nonPermissionRoot(to, from, next);
    }
  } catch (error) {
    globalMessageModule.addMessages('error', error.modelMessages(), 'next');
    next(rootLocation);
  }
};

const nonPermissionRoot: NavigationGuard = async (to, from, next) => {
  globalMessageModule.addMessages('error', ['アクセス権限がありません'], 'next');
  next(rootLocation);
};
